import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// Animation style
const styles = {
    transition: 'all 2000ms ease-out'
}

// Node settings
const playingBkg = "#ff60ea";
const hoverBkg = "#78c5b4";
const defaultScale = 1;
const activeScale = 1.2;

// Fetch audio from URL, Toggle play state, and play audio.
const useAudio = (nodeUrl, defaultBkg) => {
    
    // Default setting
    const [nodeAudio, setAudio] = useState(new Audio());
    const [nodeAudioFetched, setFetched] = useState(false);
    const [nodePlaying, setPlaying] = useState('none'); // Indicate playing state. Value: 'none', 'hover', 'click' 
    const [nodeBkg, setBackground] = useState(defaultBkg);
    const [nodeScale, setScale] = useState(1);
    const [nodeAnimation, setAnimation] = useState('');

    // Fetch audio if audio not already fetched
    const fetchAudio = () => {
        if (!nodeAudioFetched) {
            setAudio(new Audio(nodeUrl));
            setFetched(true);
        }
    }

    // Toggle play state depending on mouse event (hover, hover leave, or click)
    const togglePlayState = (e) => {
        
        if(!nodeAudioFetched){
            fetchAudio();
        }

        switch (e.type) {
            case 'click':
                nodePlaying === 'click' ? setPlaying('none') : setPlaying('click');
                break;

            case 'mouseenter':
                if (nodePlaying !== 'click') {
                    setPlaying('hover');
                }
                break;

            case 'mouseleave':
                if (nodePlaying === 'hover') {
                    setPlaying('none');
                }
                break;
            
            default:
                break;
        }
    }

    // Play the audio - click or hover play w/ fade in
    useEffect(() => {

        if (nodePlaying === 'click') {
            
            nodeAudio.currentTime = 0;

            var playPromise = nodeAudio.play();

            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    setScale(activeScale);
                    setAnimation('rotation infinite 3s linear');
                    setBackground(playingBkg);
                    nodeAudio.play();
                }).catch(error => {
                    console.error(error);
                });
            }
        } else if (nodePlaying === 'hover') {
            nodeAudio.currentTime = 20;
            let playPromise = nodeAudio.play();

            if (playPromise !== undefined) {
                playPromise.then(_ => {

                    let initial = 0;
                    nodeAudio.volume = initial;
                    
                    let eAudio = setInterval(() => {
                        initial += 0.01;
                        nodeAudio.volume = initial.toFixed(1);
                        
                        if (initial.toFixed(1) >= 0.6) {
                            clearInterval(eAudio);
                        }
                    }, 50);
                    setScale(1.2);
                    setBackground(hoverBkg);
                    nodeAudio.play();
                }).catch(error => {
                    console.error(error);
                });
            }
        } else {
            nodeAudio.pause();
            setBackground(defaultBkg);
            setAnimation('');
            setScale(defaultScale);
        }

    },[nodeAudio, nodePlaying, defaultBkg]);

    // set up event listener
    useEffect(() => {
        nodeAudio.addEventListener('ended', () => setPlaying('none'));
        return () => {
            nodeAudio.removeEventListener('ended', () => setPlaying('none'));
        };
     }, [nodeAudio]);

     return [togglePlayState, nodePlaying, nodeBkg, nodeScale, nodeAnimation];
}

// Render
const MusicNode = ({nodeId, nodeUrl, nodeText, defaultBkg, nodePosition}) => {
    const [togglePlayState, nodePlaying, nodeBkg, nodeScale, nodeAnimation] = useAudio(nodeUrl, defaultBkg);
    
    if (nodePosition !== undefined) {
        return (
            <div className={'music-node-container ' + nodeId} 

                style={{
                    ...styles,
                    left: nodePosition.split(',')[0] + "px",
                    top: nodePosition.split(',')[1] + "px"
                }}>
                <div className="music-node-player"
                    onClick={togglePlayState}
                    onMouseEnter={togglePlayState}
                    onMouseLeave={togglePlayState}
                    style={{
                        ...styles,
                        backgroundColor: nodeBkg,
                        transform: 'scale(' + nodeScale + ')',
                        zIndex: nodePlaying === 'click' ? 2 : 1,
                        animation: nodeAnimation
                    }}>
                </div>
                <div className="music-node-text"
                    style={{
                        ...styles,
                        opacity: nodePlaying === 'click' ? 1 : 0,
                        zIndex: nodePlaying === 'click' ? 3 : -1
                    }}>
                    {nodeText.split(' _ ').map((para) => {
                        return(
                            <p key={para} className='title-line'>{para}</p>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
}

MusicNode.defaultProps = {
    defaultBkg: 'white'
}

MusicNode.propTypes = {
    nodeUrl: PropTypes.string.isRequired,
    nodeText: PropTypes.string,
    nodePosition: PropTypes.string
}

export default MusicNode