import { useState, useEffect } from "react";
import MusicNode from "./MusicNode";

// Default values for MusicNodes
const defaultBkg = 'white';
const latestBkg = '#D93939';

// fetch audio URLs and generate the MusicNodes
const useGenerateNodes = () => {

    // Setup settings
    const [songList, setSongList] = useState([]);
    const [nodePositionSet, setNodePositions] = useState(new Set());
    const [songsFetched, setSongFetched] = useState(false);

    // Fetch URLs for the audio files
    useEffect(() => {

    if(!songsFetched) {
        fetch('https://halffloor.com/fetch.php')
        .then((resString) => resString.text())
        .then((resString) => {
      
          resString = resString.substring(resString.indexOf('[') + 1);
          resString = resString.substring(0, resString.indexOf(']'));
      
          let strList = resString.split(',');
          
          for (var i = 0; i < strList.length; i++) {
              strList[i] = strList[i]
                          .replace('"','')
                          .replace('"', '')
                          .replace('.mp3','');
            }
            strList.splice(0, 1);
           setSongList(strList);
           setSongFetched(true);
        }).catch((error) => {
            console.log(error);
        });
    }
    }, [songList, songsFetched]);

    // Generate seemingly random node positions
    useEffect(() => {
        // Irrational numbers makes everything seem random
        var piString = '314159265358979323846264338327950288419716939937510582097494459230781640628620899862803482534211706798214808651328230664709384460955058223172535940812848111745028410270193852110555964462294895493038196442881097566593344612847564823378678316527120190914564856692346034861045432664821339360726024914127372458700660631558817488152092096282925409171536436789259036001133053054882046652138414695194151160943305727036575959195309218611738193261179310511854807446237996274956735188575272489122793818301194912983367336244065664308602139494639522473719070217986094370277053921717629317675238467481846766940513200056812714526356082778577134275778960917363717872146844090122495343014654958537105079227968925892354201995611212902196086403441815981362';
        var eString =  '27182818284590452353602874713526624977572470936999595749669676277240766303535475945713821785251664274274663919320030599218174135966290435729003342952605956307381323286279434907632338298807531952510190115738341879307021540891499348841675092447614606680822648001684774118537423454424371075390777449920695517027618386062613313845830007520449338265602976067371132007093287091274437470472306969772093101416928368190255151086574637721112523897844250569536967707854499699679468644549059879316368892300987931277361782154249992295763514822082698951936680331825288693984964651058209392398294887933203625094431173012381970684161403970198376793206832823764648042953118023287825098194558153017567173613320698112509961818815930416903515988885193458072';    
        
        let positionList = new Set();

        // Create "random" positions for each MusicNode
        for (var i = 1; i < Math.floor(songList.length * 1.3); i++) {

            let x = ((i + 1) * (piString.substring(i, i + 2) + 1) % 999);
            let y = ((i + 1) * (eString.substring(i, i + 1) + 1) % 999);

            x = (Math.round(x / 75) * 75);
            y = (Math.round(y / 50) * 50 + 10);

            positionList.add(String(x + ',' + y));
        }

        // Return the list
        if (positionList !== []) {
            setNodePositions(Array.from(positionList));
        }
    }, [songList]);

    return [songList, nodePositionSet];
}

// Render all the nodes according to positions generated

const NodeGenerator = () => {
    const [songList, nodePositionSet] = useGenerateNodes();

    if (nodePositionSet !== undefined && nodePositionSet !== []) {
        return (
            songList.map((songName, i) => (
                <MusicNode 
                    key = { 'node_' + i }
                    nodeId = { 'node_' + i }
                    nodeUrl = { 'https://halffloor.com/sounds/' + songName + '.mp3' }
                    nodeText = { songName }
                    nodePosition = { nodePositionSet[i] }
                    defaultBkg={ i === songList.length - 1 ? latestBkg : defaultBkg }
                    ></MusicNode>
            ))
        );
    }
    else {
        return (
            <h1>
                Loading...
            </h1>
        )
    }
}

export default NodeGenerator;
