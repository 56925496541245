import { useState } from "react";
import NodeGenerator from "./components/NodeGenerator";
import InfoNode from "./components/InfoNode";

// Animation style 
const styles = {
  transition: 'all 500ms ease-out'
}

const context = new AudioContext();

// Hide Welcome Screen on click
const useHideWelcomeScreen = () => {

  // Default settings
  const [screenOpacity, setOpacity] = useState(1);
  const [screenZIndex, setZIndex] = useState(5);

  const hideWelcomeScreen = () => {
    setOpacity(0);
    setZIndex(-3);
  }

  // Attempt to unlock for iOS
  unlockIOS();

  return [hideWelcomeScreen, screenOpacity, screenZIndex];
}

// Unlocking audio capabilities for iOS :P
const unlockIOS = () => {

  var buffer = context.createBuffer(1, 1, 22050);
  var source = context.createBufferSource();
  source.buffer = buffer;
  source.connect(context.destination);

  source.start ? source.start(0) : source.noteOn(0);
}

// Render app
const App = () => {
  const [hideWelcomeScreen, screenOpacity, screenZIndex] = useHideWelcomeScreen();

  return(
    <div>
    <div
        id="welcome-screen" 
        className='welcome-screen'
        onClick={hideWelcomeScreen}
        style={{
          ...styles,
          display: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'none' : 'block',
          opacity: screenOpacity,
          zIndex: screenZIndex
        }}
    ></div>
    <NodeGenerator></NodeGenerator>
    <InfoNode></InfoNode>
  </div>
  )
}

export default App;